@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'Thumbs UP';
	src: url('../public/fonts/thumbs_up.ttf');
}

@layer base {
	html {
		font-family: 'Thumbs UP', system-ui, sans-serif;
		height: 100%;
	}

	body {
		height: 100%;
	}

	/* @media (max-width: 640px) {
		body {
			overflow: hidden;
		}
	} */

	* {
		margin: 0;
		padding: 0;
	}

	input {
		outline: none;
	}

	.homeCarousel > div.absolute {
		bottom: 0.25rem;
	}

	.homeCarousel > div.absolute > span {
		width: 0.5rem;
		height: 0.5rem;
	}
}

@layer utilities {
	/* Hide scrollbar for Chrome, Safari and Opera */
	.no-scrollbar::-webkit-scrollbar {
		display: none;
	}
	/* Hide scrollbar for IE, Edge and Firefox */
	.no-scrollbar {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}
}
