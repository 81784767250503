.animate-dot {
	transition: fill 1s ease;
	animation: colorChange 0.5s infinite;
	position: relative;
}

.animate-dot::before {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background-color: rgba(255, 255, 255, 0.8);
	animation: rippleEffect 3s infinite;
	pointer-events: none;
}

@keyframes colorChange {
	0% {
		fill: #717171;
		/* Initial color */
	}

	100% {
		fill: #ce0e2d;
		/* Final color */
	}
}

@keyframes rippleEffect {
	0% {
		transform: scale(1);
		opacity: 0.8;
	}

	100% {
		transform: scale(2);
		opacity: 0;
	}
}
